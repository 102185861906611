import React from "react";
import { useHistory } from "react-router-dom";

export default function InvoiceIssued() {
    const history = useHistory();
    const goHome = () => { history.push("/"); };
    return (
        <>
            <section class="contenido">
                <article id="blue-claro-bloque">
                    <div class="banner-factura">
                        <div class="logo-blanco-bloque">
                            <img src="img/Logo-Navy.png" class="logo-azul-marino" />
                        </div>
                    </div>
                    <div class="dos-column-emitida">
                        <div class="bloques">
                            <div class="bloque-uno-exito">
                                <img src="img/Imagen_Factura_Emitida.png" class="img-exito-emitida"/>
                            </div>
                            <div class="bloque-dos-exito">
                                <p class="titulo-bloque-centro">TU FACTURA SE HA EMITIDO CON ÉXITO</p>
                                <button class="volver-gris" onClick={goHome}>VOLVER A INICIO</button>
                                {/* <p class="texto-bloque-help">¿Necesitas ayuda?</p> */}
                            </div>
                        </div>
                    </div>
                </article>
                <section class="banner-promo">
                </section>
            </section>
        </>
    );
}