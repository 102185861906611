import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import func from "./FunctionsAdd"
import Modal from "./Modals";

export default function GenerateTicket(props) {
    //console.log(props.location.state.gisftCard)



    const [onModal, setOnModal] = useState(false);
    const [onTypeModal, setOnTypeModal] = useState('ticketInvoiced');
    const needHelp = () => { setOnTypeModal("helpContact"); setOnModal(true); }
    const history = useHistory();
    let modalMsg = onModal ? (<Modal setOnModal={setOnModal} type={onTypeModal} data={""} />) : ("");
    if (props.location.state === undefined)
        history.push("/");
    let lstArts = "";
    let ticket = props.location.state.ticket;
    let fecha = ticket.substring(0, 6);
    let anio = "20" + fecha.substring(0, 2);
    let mes = fecha.substring(2, 4);
    let dia = fecha.substring(4, 6);
    let tienda = ticket.substring(6, 10);
    let terminal = ticket.substring(10, 13);
    let transaccion = ticket.substring(13, 17)
    const goHome = () => { history.push("/"); };
    const goBack = () => { history.goBack(); };
    const goNext = () => {
        var path = {
            pathname: '/generardatos',
            state: props.location.state
        }
        history.push(path)
    };
    //debugger
    useEffect(() => {
        if (props.location.state.gisftCard) {
            setOnTypeModal("giftCard");
            setOnModal(true);
        }
    }, [])


    return (
        <>
            {modalMsg}
            <section class="contenido">
                <article id="blue-bloque">
                    <div class="banner-factura">
                        <div class="logo-blanco-bloque">
                            <img src="img/IMG_Logo_Blanco.png" class="logo-blanco" /></div>
                        <div class="titulo-ticket-bloque">
                            <p class="ticket-titulo">TU TICKET</p>
                        </div>
                    </div>
                    <div class="info-ticket">
                        <div class="contenido-info-ticket">
                            <div class="datos-ticket">
                                <div class="fecha">
                                    <p class="texto-fecha">
                                        <span class="bold-azul">Fecha:</span> {dia} - {mes} - {anio}
                                    </p>
                                </div>
                                <div class="tienda"><p class="texto-tienda">
                                    <span class="bold-azul">Tienda:</span> {tienda}</p></div>
                                <div class="caja"><p class="texto-caja"><span class="bold-azul">Caja:</span> {terminal}</p></div>
                            </div>
                            <div class="codigo-ticket"><p class="code-ticket"><span class="bold-azul">Ticket:</span> {ticket} </p></div>
                            <div class="tabla" style={{ "overflowX": "auto" }}>
                                <table class="tg">
                                    <thead>
                                        <tr>
                                            <th class="tg-uj0o"># Artículo</th>
                                            <th class="tg-uj0o">Código</th>
                                            <th class="tg-uj0o">Descripción</th>
                                            <th class="tg-uj0o">Precio</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.location.state.tks.map((tks, i) => (
                                            <tr>
                                                <td class="tg-wk8r">{i + 1}</td>
                                                <td class="tg-wk8r">{tks.CLAVEPRODSERV}</td>
                                                <td class="tg-wk8r">{tks.DESCRIPCION}</td>
                                                <td class="tg-wk8r">${func.FormatTo2Fix(tks.XTNDPRICE)}</td>
                                            </tr>
                                        ))
                                        }
                                        <tr>
                                            <td class="tg-oe15"></td>
                                            <td class="tg-oe15"></td>
                                            <td class="tg-oe15"></td>
                                            <td class="tg-oe15"></td>
                                        </tr>
                                        <tr>
                                            <td class="tg-oe15"></td>
                                            <td class="tg-oe15"></td>
                                            <td class="tg-oe15"></td>
                                            <td class="tg-oe15"></td>
                                        </tr>
                                        <tr>
                                            <td class="tg-oe15"></td>
                                            <td class="tg-oe15"></td>
                                            <td class="tg-oe15"></td>
                                            <td class="tg-oe15"></td>
                                        </tr>
                                        <tr>
                                            <td class="tg-ah3a">Total</td>
                                            <td class="tg-ah3a"></td>
                                            <td class="tg-bwi9"></td>
                                            <td class="tg-oe16">${func.FormatTo2Fix(props.location.state.importe)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="botones-genera-ticket">
                                <button class="btn-atras-ticket" onClick={goBack}>ATRÁS</button>
                                <button class="btn-inicio-ticket" onClick={goHome}>INICIO</button>
                                <button class="btn-next-ticket" onClick={goNext}>SIGUIENTE</button>
                            </div>
                        </div>
                    </div>
                </article>
                <section class="banner-gris">
                    <div class="bloque-gris-help">
                        <div class="gris-contenido-help">
                            <p class="gris-texto-help" style={{ cursor: "pointer" }} onClick={needHelp}>¿Necesitas ayuda?</p>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}