import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";

import Home from "./Home";
import FindTicket from "./FindTicket";
import Reprint from "./Reprint";
import GenerateTicket from "./GenerateTicket";
import GenerateEmission from "./GenerateEmission";
import DataClient from "./DataClient";
import GenerateNotice from "./GenerateNotice";
import InvoiceErrorIssue from "./InvoiceErrorIssue";
import InvoiceIssued from "./InvoiceIssued";
import Cancel from "./Cancel";
import PrivaciNotice from "./PrivacyNotice";



function App() {
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/home" children={<Home />} />
                    <Route path="/buscarticket" component={FindTicket} />
                    <Route path="/generarticket" component={GenerateTicket} />
                    <Route path="/generaremision" component={GenerateEmission} />
                    <Route path="/generardatos" component={DataClient} />
                    <Route path="/generarnotificacion" component={GenerateNotice} />
                    <Route path="/facturaemitida" component={InvoiceIssued} />
                    <Route path="/cancelar" component={Cancel} />
                    <Route path="/error-emision-factura" component= {InvoiceErrorIssue} />
                    <Route path="/reimprimir" component={Reprint} />
                    <Route path="/privacy" component={PrivaciNotice} />
                    {/* <Route exact path="/:qr" component={PreDashBoard} /> */}
                    <Route  path="/" component={PreDashBoard} />
                    <Route path="*" component={PreDashBoard} />
                </Switch>
            </Router>

        </>
    );
}
export default App;

const PreDashBoard = () => {
    const history = useHistory();
    useEffect(() => {
        // history.push("/Home");
        window.location.href='/Home'
        //console.log('si entre')
    }, []);
    return (
        <>
        </>
    )
}