import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "./Modals";

export default function Home() {
    const [onModal, setOnModal] = useState(false);
    let modalMsg = onModal ? (<Modal setOnModal={setOnModal} type={"helpCancel"} data={""} />) : ("");
    const ShowModal = () => { setOnModal(true); };


    const history = useHistory();

    const rePrint = () => { history.push("/reimprimir") };
    const cancelTicket = () => { history.push("/cancelar") };
    const orderTicket = () => { history.push("/buscarticket") }


    return (
        <>
            {modalMsg}
            <article id="left-gif">
                <a href="https://www.petco.com.mx/petco/en/login">
                    <div class="img-inicio-gif" >
                        <img src="img/Gif_Facturación.gif" id="img-gif-inicio" />
                    </div>
                </a>
            </article>
            <article id="right-gif">
                <div class="contenido-home"> <button class="btn-facturacion">
                    <p class="text-btn-factu">FACTURACIÓN</p>
                </button>
                    <div class="logo">
                        <img src="img/IMG_Logo_Blanco.png" id="logo" />
                    </div>
                    <div class="btn-acciones">
                        <button class="btn-obtener" onClick={orderTicket}><img src="img/icon-obtener.png" class="icon-inicio" />OBTENER</button>
                        <button class="btn-reimprimir" onClick={rePrint}><img src="img/icon-reimprimir.png" class="icon-inicio" />REIMPRIMIR</button>
                        {/* <button class="btn-cancelar" onClick={cancelTicket}><img src="img/icon-cancelar.png" class="icon-inicio" />CANCELAR</button> */}
                    </div>
                </div>
            </article>
            <section class="banner-foot" style={{ "position": "none" }}>
                <div class="contenido-banner-foot">
                    <div class="bann-uno"><img src="img/banner-inicio-uno.png" class="bann-img" /></div>
                    <div class="bann-dos">
                        <p class="texto-bann">¡CONSIENTE A TU MASCOTA CON LOS MEJORES PRODUCTOS!</p>
                    </div>
                    <div class="bann-tres"><img src="img/banner-inicio-dos.png" class="bann-img-dos" /></div>
                </div>
            </section>
        </>
    );
}