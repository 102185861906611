//const REACT_APP_WALLIA_API="http://localhost:4001/"; //local
//const REACT_APP_WALLIA_API="http://petco-facturacion-qa.us-east-2.elasticbeanstalk.com/"; //QA
const REACT_APP_WALLIA_API="https://petco-fact-prd.wallia.cloud/"; //Produccion
const EjectFecth = async(endpoint,params) => {
    let jsonres = await fetch(REACT_APP_WALLIA_API + endpoint, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            'Authorization':'Basic YWRtaW46NT0yaDc4LXAmVGItVWVSTA=='
        },
        //mode:"no-cors",
        body: JSON.stringify(params),
    });
    var jsonAuthResponse = await jsonres.json();
    return jsonAuthResponse;
}
const FormatTo2Fix=(cad)=>{
    let valRet="";
    if(cad.toString().indexOf(".")==-1){
        valRet=cad + ".00";
    }else{
        ////debugger;
        let arrNum=cad.toString().split(".");
        valRet= arrNum[0] + "." + pad2(arrNum[1])
    }
    return valRet;
}
const pad2 = (n) => {
    return (n < 10 ? '0' : '') + n;
}
const FormatDatetoyyyyMMddHHmmss = (date) => {

    return date.getFullYear() +

        pad2(date.getMonth() + 1) +

        pad2(date.getDate()) +

        pad2(date.getHours()) +

        pad2(date.getMinutes()) +

        pad2(date.getSeconds());

}
const func = {
    EjectFecth,
    FormatTo2Fix,
    FormatDatetoyyyyMMddHHmmss
}
export default func;
// module.exports = {
//     EjectFecth,
//     FormatTo2Fix,
//     FormatDatetoyyyyMMddHHmmss
// };