import React, { useState }  from "react";
import { useHistory } from "react-router-dom";
import Modal from "./Modals";

export default function PrivaciNotice(){

    const history = useHistory();
    const goBack = () => { history.goBack(); };
    const [onModal, setOnModal] = useState(false);
    const [onTypeModal, setOnTypeModal] = useState("");
    const needHelp = () => { setOnTypeModal("helpContact"); setOnModal(true); };
    let modalMsg = onModal ? (<Modal setOnModal={setOnModal} type={onTypeModal} data={""} />) : ("");
    return(
        <>
        {modalMsg}
            <section class="contenido">
                            <article id="blue-bloque">
                                <div class="banner-factura">
                                    <div class="logo-blanco-bloque">
                                        <img src="img/IMG_Logo_Blanco.png" class="logo-blanco" /></div>
                                    <div class="titulo-ticket-bloque">
                                        <p class="ticket-titulo">AVISO DE PRIVACIDAD</p>
                                    </div>
                                </div>
                                <div class="info-ticket-cliente">
                                    <div class="contenido-info-ticket-cliente">
                                        <p class="aviso-priva-titulo">Aviso de Privacidad</p>
                                        <p class="aviso-priva-subtitle">Responsable</p>
                                        <p class="aviso-priva-texto">Razón social: Mascotas y Compañia SAPI de C.V.</p>
                                        <p class="aviso-priva-texto">Nombre comercial: Petco México</p>
                                        <p class="aviso-priva-subtitle">Domicilio</p>
                                        <p class="aviso-priva-texto">Av. Ejército Nacional No. 769, Col. Granada, C.P. 11520, Delegación Miguel Hidalgo, México, Ciudad de México.</p>
                                        <p class="aviso-priva-subtitle">Finalidades de tratamiento que originan una relación jurídica con el responsable</p>
                                        <p class="aviso-priva-texto">Capturar sus datos en el sistema de la empresa, entregarle la factura electrónica solicitada y mantener un adecuado control contable en los sistemas de la empresa.</p>
                                        <p class="aviso-priva-subtitle">Finalidades secundarias</p>
                                        <p class="aviso-priva-texto">Mercadotecnia, prospección comercial y publicitarios. Para mayor información acerca del
                                            tratamiento de sus datos y de los derechos que puede usted hacer valer así como la limitación de uso o divulgación, ponemos a su disposición el aviso de privacidad integral en la página de internet.</p>
                                        <p class="centro-texto-aviso">www.petco.com.mx</p>
                                        <div class="botones-genera-ticket">
                                            <button class="btn-atras-ticket" onClick={goBack}>REGRESAR</button>
                                            {/* <button class="btn-next-ticket ">ACEPTO</button> */}
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <section class="banner-gris-aviso">
                                <div class="bloque-gris-help">
                                    <div class="gris-contenido-help">
                                        <p class="gris-texto-help" onClick={needHelp}>¿Necesitas ayuda?</p>
                                    </div>
                                </div>
                            </section>
                        </section>
        </>
    );
}