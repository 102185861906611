import { style } from "@mui/system";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export default function Modal({ type, setOnModal }) {
    let contModal = "";

    const history = useHistory();
    const goHome = () => {
        history.push("/home");
        // history.push("/home");
    };
    const goRePrint = () => {
        history.push("/reimprimir")
    }
    const [onDisplay, setOnDisplay] = useState(true);
    const [onDisplay2, setOnDisplay2] = useState(false);
    let estilo = true;
    let estilo2 = false;

    const tienda = () => {
        setOnDisplay(true);
        setOnDisplay2(false);

    }
    const linea = () => {
        setOnDisplay(false);
        setOnDisplay2(true);
    }

    switch (type) {
        case "ayuda":
            contModal = (
                <section class="contenido-modal">
                    <div class="modal-ayuda-ordenar">
                        <div class="contenido-ayuda-modal">
                            <div class="icon-close">
                                <img src="img/close.png" class="close" onClick={() => { setOnModal(false); }} />
                            </div>
                            <div class="subtitle-modal">
                                <p class="texto-modal">Identifica la información necesaria para generar tu factura</p>
                            </div>
                            <div class="botones-compra">
                                <button class="btn-compra" onClick={tienda}><span style={{ display: onDisplay == true ? <style>color: #00205B;</style> : "" }}>Si tu compra fue en Tienda</span></button>
                                <p class="texto-separador">|</p>
                                <button class="btn-compra" onClick={linea}><span style={{ display: onDisplay == false ? "" : "" }}>Si tu compra fue en Línea</span></button>
                            </div>
                            <div id="Tienda" class="compra" style={{ display: onDisplay == true ? "flex" : "none" }}>
                                <img src="img/img-ticket-full.png" class="ticket" />
                            </div>
                            <div id="Linea" class="compra" style={{ display: onDisplay2 == true ? "table-cell" : "none" }}>
                                <div class="texto-modal-informe">
                                    <p class="texto-ticket-modal-nuevo" />
                                    1.- Ingresa a tu cuenta Club Petco<br />
                                    2.- En el apartado Mi Cuenta selecciona Historial de Pedidos<br />
                                    3.- Da clic en el pedido que deseas facturar y encontrarás el ID Factura
                                    <p />
                                </div>
                                <img src="img/img-pedido-ticket.png" class="ticket2" />
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;
        case "razonSocilModal":
            contModal = (
                //<section class="contenido-modal  modal-razon-Social"">
                <div class="modalSyle">
                    <div className="containerModal">
                        <section class="contenido-modal-constancia">
                            <div class="bloque1-constancia-modal">
                                <div class="close-1-1"><img src="img/close.png" class="icon-close-1" onClick={() => { setOnModal(false); }} /></div>
                                <div class="caja1-bloque1-constancia">
                                    <div class="caja-mini-modal">
                                        <img src="img/bubble.png" class="bubble-constancia" />
                                        <p class="texto1-constancia-modal"><span class="bold-constancia">NOMBRE DEL EMISOR.</span> Se debe registrar
                                            tal y como se encuentra en la Constancia, mayúsculas, respetando números, espacios y signos de puntuación
                                        </p>
                                    </div>
                                    <img src="img/titulo-1.png" class="img-bloque1-modal" />
                                </div>
                                <div class="bloque2-constancia">
                                    <div class="caja2-constancia-titulo">
                                        <p class="titulo1-constancia">Datos de Identificación del Contribuyente</p>
                                    </div>
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td><span class="bold-constancia">RFC:</span></td>
                                                <td>MAS121116E51</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Denominación / Razón Social:</span></td>
                                                <td style={{
                                                    border: "4px solid #02a8dd",
                                                    // "solid": #02a8dd,
                                                    padding: "8px 10px",
                                                    borderRadius: "8px"
                                                }}> MASCOTAS Y COMPAÑIA</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Régimen Cpital:</span></td>
                                                <td>SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN DE CAPITAL VARIABLE</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Nombre Comercial:</span></td>
                                                <td>MASCOTAS Y COMPAÑIA</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Fecha inicio de operaciones:</span></td>
                                                <td>16 DE NOVIEMBRE DE 2012</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Estatus en el padrón:</span></td>
                                                <td>ACTIVO</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Fecha de último cambio de estado:</span></td>
                                                <td>27 DE NOVIEMBRE DE 2012</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Datos del domicilio Registrado</p>
                                </div>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td style={{
                                                "border": "4px solid #02a8dd",
                                                "padding": "8px 10px",
                                                "border-radius": "8px"
                                            }}>
                                                <span class="bold-constancia">Código Postal:</span> 11520</td>
                                            <td><span class="bold-constancia">Tipo de Vialidad:</span> AVENIDA (AV.)</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia"> Nombre de Vialidad:</span> EJÉRCITO NACIONAL</td>
                                            <td><span class="bold-constancia">Número exterior:</span> 760</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Número Interior:</span> TORRE B PISO 12</td>
                                            <td><span class="bold-constancia">Nombre de la Colonia:</span> Granasa</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Nombre de la Localidad:</span> MIGUEL HIDALGO</td>
                                            <td><span class="bold-constancia">Nombre del Municipio o Demarcación Territorial:</span> MIGUEL HIDALGO</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Nombre de la Entidad Federativa:</span> CIUDAD DE MÉXICO</td>
                                            <td><span class="bold-constancia">Entre Calle:</span> MOLIERE</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Y Calle:</span> MIGUEL DE CERVANTES SAAVEDRA</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Actividades Económicas:</p>
                                </div>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Orden</th>
                                            <th>Actividad Ecónómica</th>
                                            <th>Porcentaje</th>
                                            <th>Fecha de Inicio</th>
                                            <th>Fecha Fin</th>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td></td>
                                            <td>100</td>
                                            <td style={{ "text-align": "center" }}>16/10/2012</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Regimenes</p>
                                </div>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Régimen</th>
                                            <th>Fecha de inicio</th>
                                            <th>Fecha Fin</th>
                                        </tr>
                                        <tr>
                                            <td style={{
                                                "border": "4px solid #02a8dd",
                                                "padding": "8px 10px",
                                                "border-radius": "8px"
                                            }}>Régimen General de Ley de Persona Morales</td>
                                            <td style={{ "text-align": "center" }}>01/01/2013</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Obligaciones</p>
                                </div>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Descripción de la Obligación</th>
                                            <th>Descripción Vencimiento</th>
                                            <th>Fecha Inicio</th>
                                            <th>Fecha Fin</th>
                                        </tr>
                                        <tr>
                                            <td>Declaración de Proovedores de IVA</td>
                                            <td>A más tardar el último día del mes inmediato posterior al periodo que corresponda.</td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Declaración Informativa anual de Pagos y relaciones de servicios profesionales. Persona Morales.
                                                Impuesto sobre la Renta</td>
                                            <td>A más tardar el 15 de febrero del año siguiente.</td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Declaración Informativa de IVA con la anual de ISR</td>
                                            <td>Conjuntamente con la declaración anual del ejercicio. </td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Pago definitivo mensual de IVA</td>
                                            <td>A más tardar el 17 del mes inmediato.</td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="bloque3-constancia">
                                    <img src="img/footer1.png" class="img-bloque1-modal" />
                                </div>
                                <div class="bloque4-constancia">
                                    <img src="img/ejemplo1.png" class="img-bloque1-modal1" />
                                    <img src="img/ejemplo2.png" class="img-bloque1-modal1" />
                                </div>
                            </div>
                        </section >
                    </div>
                </div>
            );
            break;
        case "modalCp":
            contModal = (
                <div class="modalSyle">
                    <div className="containerModal">
                        <section class="contenido-modal-constancia">
                            <div class="bloque1-constancia-modal">
                                <div class="close-1-1"><img src="img/close.png" class="icon-close-1" onClick={() => { setOnModal(false); }} /></div>
                                <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Datos del domicilio Registrado</p>
                                </div>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td style={{
                                                "border": "4px solid #02a8dd",
                                                "padding": "8px 10px",
                                                "border-radius": "8px"
                                            }}>
                                                <span class="bold-constancia">Código Postal:</span> 11520</td>
                                            <td><span class="bold-constancia">Tipo de Vialidad:</span> AVENIDA (AV.)</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia"> Nombre de Vialidad:</span> EJÉRCITO NACIONAL</td>
                                            <td><span class="bold-constancia">Número exterior:</span> 760</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Número Interior:</span> TORRE B PISO 12</td>
                                            <td><span class="bold-constancia">Nombre de la Colonia:</span> Granasa</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Nombre de la Localidad:</span> MIGUEL HIDALGO</td>
                                            <td><span class="bold-constancia">Nombre del Municipio o Demarcación Territorial:</span> MIGUEL HIDALGO</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Nombre de la Entidad Federativa:</span> CIUDAD DE MÉXICO</td>
                                            <td><span class="bold-constancia">Entre Calle:</span> MOLIERE</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Y Calle:</span> MIGUEL DE CERVANTES SAAVEDRA</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Actividades Económicas:</p>
                                </div> */}
                                {/* <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Orden</th>
                                            <th>Actividad Ecónómica</th>
                                            <th>Porcentaje</th>
                                            <th>Fecha de Inicio</th>
                                            <th>Fecha Fin</th>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td></td>
                                            <td>100</td>
                                            <td style={{ "text-align": "center" }}>16/10/2012</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table> */}
                                {/* <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Regimenes</p>
                                </div> */}
                                {/* <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Régimen</th>
                                            <th>Fecha de inicio</th>
                                            <th>Fecha Fin</th>
                                        </tr>
                                        <tr>
                                            <td style={{
                                                "border": "4px solid #02a8dd",
                                                "padding": "8px 10px",
                                                "border-radius": "8px"
                                            }}>Régimen General de Ley de Persona Morales</td>
                                            <td style={{ "text-align": "center" }}>01/01/2013</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table> */}
                                {/* <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Obligaciones</p>
                                </div>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Descripción de la Obligación</th>
                                            <th>Descripción Vencimiento</th>
                                            <th>Fecha Inicio</th>
                                            <th>Fecha Fin</th>
                                        </tr>
                                        <tr>
                                            <td>Declaración de Proovedores de IVA</td>
                                            <td>A más tardar el último día del mes inmediato posterior al periodo que corresponda.</td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Declaración Informativa anual de Pagos y relaciones de servicios profesionales. Persona Morales.
                                                Impuesto sobre la Renta</td>
                                            <td>A más tardar el 15 de febrero del año siguiente.</td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Declaración Informativa de IVA con la anual de ISR</td>
                                            <td>Conjuntamente con la declaración anual del ejercicio. </td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Pago definitivo mensual de IVA</td>
                                            <td>A más tardar el 17 del mes inmediato.</td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table> */}
                                {/* <div class="bloque3-constancia">
                                    <img src="img/footer1.png" class="img-bloque1-modal" />
                                </div>
                                <div class="bloque4-constancia">
                                    <img src="img/ejemplo1.png" class="img-bloque1-modal1" />
                                    <img src="img/ejemplo2.png" class="img-bloque1-modal1" />
                                </div> */}
                            </div>
                        </section >
                    </div>
                </div>

            );
            break;
        case "modalRegimneFis":
            contModal = (
                <div class="modalSyle">
                    <div className="containerModal">
                        <section class="contenido-modal-constancia">
                            <div class="bloque1-constancia-modal">
                                <div class="close-1-1"><img src="img/close.png" class="icon-close-1" onClick={() => { setOnModal(false); }} /></div>
                                {/* <div class="caja1-bloque1-constancia">
                                    <div class="caja-mini-modal">
                                        <img src="img/bubble.png" class="bubble-constancia" />
                                        <p class="texto1-constancia-modal"><span class="bold-constancia">NOMBRE DEL EMISOR.</span> Se debe registrar
                                            tal y como se encuentra en la Constancia, mayúsculas, respetando números, espacios y signos de puntuación
                                        </p>
                                    </div>
                                    <img src="img/titulo-1.png" class="img-bloque1-modal" />
                                </div>
                                <div class="bloque2-constancia">
                                    <div class="caja2-constancia-titulo">
                                        <p class="titulo1-constancia">Datos de Identificación del Contribuyente</p>
                                    </div>
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td><span class="bold-constancia">RFC:</span></td>
                                                <td>MAS121116E51</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Denominación / Razón Social:</span></td>
                                                <td style={{
                                                    border: "4px solid #02a8dd",
                                                    // "solid": #02a8dd,
                                                    padding: "8px 10px",
                                                    borderRadius: "8px"
                                                }}> MASCOTAS Y COMPAÑIA</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Régimen Cpital:</span></td>
                                                <td>SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN DE CAPITAL VARIABLE</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Nombre Comercial:</span></td>
                                                <td>MASCOTAS Y COMPAÑIA</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Fecha inicio de operaciones:</span></td>
                                                <td>16 DE NOVIEMBRE DE 2012</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Estatus en el padrón:</span></td>
                                                <td>ACTIVO</td>
                                            </tr>
                                            <tr>
                                                <td><span class="bold-constancia">Fecha de último cambio de estado:</span></td>
                                                <td>27 DE NOVIEMBRE DE 2012</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Datos del domicilio Registrado</p>
                                </div>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td style={{
                                                "border": "4px solid #02a8dd",
                                                "padding": "8px 10px",
                                                "border-radius": "8px"
                                            }}>
                                                <span class="bold-constancia">Código Postal:</span> 11520</td>
                                            <td><span class="bold-constancia">Tipo de Vialidad:</span> AVENIDA (AV.)</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia"> Nombre de Vialidad:</span> EJÉRCITO NACIONAL</td>
                                            <td><span class="bold-constancia">Número exterior:</span> 760</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Número Interior:</span> TORRE B PISO 12</td>
                                            <td><span class="bold-constancia">Nombre de la Colonia:</span> Granasa</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Nombre de la Localidad:</span> MIGUEL HIDALGO</td>
                                            <td><span class="bold-constancia">Nombre del Municipio o Demarcación Territorial:</span> MIGUEL HIDALGO</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Nombre de la Entidad Federativa:</span> CIUDAD DE MÉXICO</td>
                                            <td><span class="bold-constancia">Entre Calle:</span> MOLIERE</td>
                                        </tr>
                                        <tr>
                                            <td><span class="bold-constancia">Y Calle:</span> MIGUEL DE CERVANTES SAAVEDRA</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table> */}
                                <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Actividades Económicas:</p>
                                </div>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Orden</th>
                                            <th>Actividad Ecónómica</th>
                                            <th>Porcentaje</th>
                                            <th>Fecha de Inicio</th>
                                            <th>Fecha Fin</th>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td></td>
                                            <td>100</td>
                                            <td style={{ "text-align": "center" }}>16/10/2012</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Regimenes</p>
                                </div>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Régimen</th>
                                            <th>Fecha de inicio</th>
                                            <th>Fecha Fin</th>
                                        </tr>
                                        <tr>
                                            <td style={{
                                                "border": "4px solid #02a8dd",
                                                "padding": "8px 10px",
                                                "border-radius": "8px"
                                            }}>Régimen General de Ley de Persona Morales</td>
                                            <td style={{ "text-align": "center" }}>01/01/2013</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>

                                {/* <div class="caja2-constancia-titulo">
                                    <p class="titulo1-constancia">Obligaciones</p>
                                </div>
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th>Descripción de la Obligación</th>
                                            <th>Descripción Vencimiento</th>
                                            <th>Fecha Inicio</th>
                                            <th>Fecha Fin</th>
                                        </tr>
                                        <tr>
                                            <td>Declaración de Proovedores de IVA</td>
                                            <td>A más tardar el último día del mes inmediato posterior al periodo que corresponda.</td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Declaración Informativa anual de Pagos y relaciones de servicios profesionales. Persona Morales.
                                                Impuesto sobre la Renta</td>
                                            <td>A más tardar el 15 de febrero del año siguiente.</td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Declaración Informativa de IVA con la anual de ISR</td>
                                            <td>Conjuntamente con la declaración anual del ejercicio. </td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Pago definitivo mensual de IVA</td>
                                            <td>A más tardar el 17 del mes inmediato.</td>
                                            <td style={{ "text-align": "center" }}>16/11/2012</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="bloque3-constancia">
                                    <img src="img/footer1.png" class="img-bloque1-modal" />
                                </div>
                                <div class="bloque4-constancia">
                                    <img src="img/ejemplo1.png" class="img-bloque1-modal1" />
                                    <img src="img/ejemplo2.png" class="img-bloque1-modal1" />
                                </div> */}
                            </div>
                        </section >
                    </div>
                </div>
            );
            break;
        case "printSuccess":
            contModal = (
                <section class="contenido-modal-column">
                    <div class="modal-column">
                        <div class="contenido-bloques">
                            <div class="bloques">
                                <div class="bloque-uno-exito">
                                    <img src="img/IMG_REIMPRIMIR_EXITO.png" class="img-bloque-exito" />
                                </div>
                                <div class="bloque-dos-exito">
                                    <p class="titulo-bloque-centro">¡DESCARGA REALIZADA CON ÉXITO!</p>
                                    <button class="volver" onClick={goHome}>VOLVER A INICIO</button>
                                    <p class="texto-bloque-help" style={{ "cursor": "pointer" }}>¿Necesitas ayuda?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;

        case "printError":
            contModal = (
                <section class="contenido-modal-column">
                    <div class="modal-column">
                        <div class="contenido-bloques">
                            <div class="bloques">
                                <div class="bloque-uno-error">
                                    <img src="img/IMG_REIMPRIMIR_ERRPR.png" class="img-bloque-error" />
                                </div>
                                <div class="bloque-dos-error">
                                    <p class="titulo-bloque-centro">¡ALGO SALIÓ MAL!</p>
                                    <p class="texto-bloque-error">
                                        Tu factura no pudo ser descargada, inténtalo de nuevo
                                    </p>
                                    <button class="back">REGRESAR</button>
                                    <button class="volver" onClick={goHome}>VOLVER A INICIO</button>
                                    <p class="texto-bloque-help" style={{ "cursor": "pointer" }}>¿Necesitas ayuda?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            );
            break;

        case "cancelSuccess":
            contModal = (
                <section class="contenido-modal-column">
                    <div class="modal-column">
                        <div class="contenido-bloques">
                            <div class="bloques">
                                <div class="bloque-uno-exito">
                                    <img src="img/IMG_CANCELAR_EXITO.png" class="img-bloque-cancelacion-exito" />
                                </div>
                                <div class="bloque-dos-exito">
                                    <p class="titulo-bloque-centro">¡TU FACTURA SE HA CANCELADO!</p>
                                    <button class="volver" style={{ "cursor": "pointer" }} onClick={goHome} >VOLVER A INICIO</button>
                                    <p class="texto-bloque-help" style={{ "cursor": "pointer" }}>¿Necesitas ayuda?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;

        case "cancelError":
            contModal = (
                <section class="contenido-modal-column">
                    <div class="modal-column">
                        <div class="contenido-bloques">
                            <div class="bloques">
                                <div class="bloque-uno-error">
                                    <img src="img/IMG_CANCELAR_ERROR.png" class="img-bloque-error" style={{ "cursor": "pointer" }} />
                                </div>
                                <div class="bloque-dos-error">
                                    <p class="titulo-bloque-centro">¡ALGO SALIÓ MAL!</p>
                                    <p class="texto-bloque-error">
                                        Tu factura no pudo ser cancelada, inténtalo de nuevo
                                    </p>
                                    <button class="back">REGRESAR</button>
                                    <button class="volver">VOLVER A INICIO</button>
                                    <p class="texto-bloque-help" style={{ "cursor": "pointer" }}>¿Necesitas ayuda?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;

        case "giftCard":
            contModal = (
                <section class="contenido-modal-column">
                    <div class="modal-column">
                        <div class="contenido-bloques">
                            <div class="bloques">
                                <div class="bloque-uno-error">
                                    <img src="img/IMG_CANCELAR_ERROR.png" class="img-bloque-error" />
                                </div>
                                <div class="bloque-dos-error">
                                    <p class="titulo-bloque-centro">¡Tu ticket Contine una GiftCard!</p>
                                    <p class="texto-bloque-error">
                                    Se generará su factura sin incluir las tarjetas de regalo, estos artículos solo son facturables hasta el momento de su utilización, solo se factura  lo que se adquiera con las mismas. Si tiene alguna duda comunicarse al (55) 5557 2500. mesadeayuda@petco.com.mx. Agradecemos su compra.
                                    </p>
                                    <button class="volver" onClick={() => { setOnModal(false); }} style={{ "cursor": "pointer" }}>ACEPTAR</button>
                                    {/* <p class="texto-bloque-help" style={{ "cursor": "pointer" }}>¿Necesitas ayuda?</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;
            case "avisoDePoliticas":
            contModal = (
                <section class="contenido-modal-column">
                    <div class="modal-column">
                        <div class="contenido-bloques">
                            <div class="bloques">
                                <div class="bloque-uno-error">
                                    <img src="img/IMG_CANCELAR_ERROR.png" class="img-bloque-error" />
                                </div>
                                <div class="bloque-dos-error">
                                    {/* <p class="titulo-bloque-centro">¡Tu ticket Contine una GiftCard!</p> */}
                                    <p class="texto-bloque-error">
                                    Es necesario aceptar el Aviso de Privacidad para proceder a su facturación.
                                    </p>
                                    <button class="volver" onClick={() => { setOnModal(false); }} style={{ "cursor": "pointer" }}>ACEPTAR</button>
                                    {/* <p class="texto-bloque-help" style={{ "cursor": "pointer" }}>¿Necesitas ayuda?</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;

            case "warningFact":
            contModal = (
                <section class="contenido-modal-column">
                    <div class="modal-column">
                        <div class="contenido-bloques">
                            <div class="bloques">
                                <div class="bloque-uno-error">
                                    <img src="img/IMG_CANCELAR_ERROR.png" class="img-bloque-error" />
                                </div>
                                <div class="bloque-dos-error">
                                    {/* <p class="titulo-bloque-centro">¡Tu ticket Contine una GiftCard!</p> */}
                                    <p class="texto-bloque-error">
                                    Les recordamos que a partir del cambio de versión 4.0 de CFDI, es necesario validar el RFC, Nombre o Razón Social, Régimen.
                                    </p>
                                    <button class="volver" onClick={() => { setOnModal(false); }} style={{ "cursor": "pointer" }}>ACEPTAR</button>
                                    {/* <p class="texto-bloque-help" style={{ "cursor": "pointer" }}>¿Necesitas ayuda?</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;


        case "ticketConGisftCard":
            contModal = (
                <section class="contenido-modal-column">
                    <div class="modal-column">
                        <div class="contenido-bloques">
                            <div class="bloques">
                                <div class="bloque-uno-error">
                                    <img src="img/IMG_CANCELAR_ERROR.png" class="img-bloque-error" />
                                </div>
                                <div class="bloque-dos-error">
                                    <p class="titulo-bloque-centro">¡ALGO SALIÓ MAL!</p>
                                    <p class="texto-bloque-error">
                                    Su factura no puede ser emitida debido a que las tarjetas de regalo solo son facturables hasta el momento de su utilización, solo se factura  lo que se adquiera con las mismas. Si tiene alguna duda comunicarse al (55) 5557 2500. mesadeayuda@petco.com.mx.  Agradecemos su compra.
                                    </p>
                                    <button class="volver" onClick={() => { setOnModal(false); }} style={{ "cursor": "pointer" }}>ACEPTAR</button>
                                    {/* <p class="texto-bloque-help" style={{ "cursor": "pointer" }}>¿Necesitas ayuda?</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;

        case "helpContact":
            contModal = (
                <section class="contenido-modal-column">
                    <div class="modal-column">
                        <div class="contenido-bloques">
                            <div class="icon-close-bloque" onClick={() => { setOnModal(false); }}>
                                <img src="img/close.png" class="close" style={{ "cursor": "pointer" }} />
                            </div>
                            <div class="bloques">
                                <div class="bloque-uno">
                                    <img src="img/IMG_AYUDA.png" class="img-bloque" />
                                </div>
                                <div class="bloque-dos">
                                    <p class="titulo-bloque">Si necesitas ayuda ¡No dudes en contactarnos!</p>
                                    <p class="texto-bloque">Envíanos un correo a info@petco.com.mx o llámanos al <br />800 32 73826</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;
        case "helpCancel":
            contModal = (
                <section class="contenido-modal">
                    <div class="modal-ayuda-ordenar">
                        <div class="contenido-ayuda-modal1">
                            <div class="icon-close" style={{ "cursor": "pointer" }}>
                                <img src="img/close.png" class="close" onClick={() => { setOnModal(false); }} />
                            </div>
                            <div class="subtitle-modal">
                                <p class="texto-modal">Identifica la información necesaria para cancelar tu factura</p>
                            </div>
                            <div class="ticket-cancelar">
                                <img src="img/IMG_FACTURA_AMPLIA.png" class="ticket-cancel" />
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;

        case "loader":
            contModal = (
                <div class="loader">
                    <img class="load" src="img/Loader.gif" alt="Cargando..." />
                </div>
            );
            break;
        case "ticketNotFound":
            contModal = (
                <section class="contenido-modal-column">
                    <div class="modal-column">
                        <div class="contenido-bloques">
                            <div class="bloques">
                                <div class="bloque-uno-error">
                                    <img src="img/IMG_CANCELAR_ERROR.png" class="img-bloque-error" />
                                </div>
                                <div class="bloque-dos-error">
                                    <p class="titulo-bloque-centro">¡ALGO SALIÓ MAL!</p>
                                    <p class="texto-bloque-error">
                                        Ticket no encontrado, inténtalo de nuevo
                                    </p>
                                    <button class="volver" onClick={() => { setOnModal(false); }} style={{ "cursor": "pointer" }}>ACEPTAR</button>
                                    {/* <p class="texto-bloque-help" style={{ "cursor": "pointer" }}>¿Necesitas ayuda?</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;
        case "ticketNotFoundTw":
                contModal = (
                    <section class="contenido-modal-column">
                        <div class="modal-column">
                            <div class="contenido-bloques">
                                <div class="bloques">
                                    <div class="bloque-uno-error">
                                        <img src="img/IMG_CANCELAR_ERROR.png" class="img-bloque-error" />
                                    </div>
                                    <div class="bloque-dos-error">
                                        <p class="titulo-bloque-centro">¡ALGO SALIÓ MAL!</p>
                                        <p class="texto-bloque-error">
                                            Ticket no encontrado, en TeamWork
                                        </p>
                                        <button class="volver" onClick={() => { setOnModal(false); }} style={{ "cursor": "pointer" }}>ACEPTAR</button>
                                        {/* <p class="texto-bloque-help" style={{ "cursor": "pointer" }}>¿Necesitas ayuda?</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                );
                break;
        case "ticketInvoiced":
            contModal = (
                <section class="contenido-modal-column">
                    <div class="modal-column">
                        <div class="contenido-bloques">
                            <div class="bloques">
                                <div class="bloque-uno-error">
                                    <img src="img/IMG_REIMPRIMIR_ERRPR.png" class="img-bloque-error" />
                                </div>
                                <div class="bloque-dos-error">
                                    <p class="titulo-bloque-centro">¡EL TICKET YA FUÉ FACTURADO!</p>
                                    <p class="texto-bloque-error">
                                    </p>
                                    <button class="back" onClick={goRePrint} style={{ "cursor": "pointer" }}>IR A IMPRESIÓN</button>
                                    <button class="volver" onClick={() => { setOnModal(false); }} style={{ "cursor": "pointer" }}>ACEPTAR</button>
                                    <p class="texto-bloque-help" style={{ "cursor": "pointer" }}>¿Necesitas ayuda?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
            break;
    }
    return (
        <>
            {contModal}
        </>
    );
}
