import React from "react";

export default function InvoiceErrorIssue() {
    return (
        <>
            <section class="contenido">
                <article id="blue-claro-bloque">
                    <div class="banner-factura">
                        <div class="logo-blanco-bloque">
                            <img src="img/Logo-Navy.png" class="logo-azul-marino" /> </div>
                    </div>
                    <div class="dos-column-emitida">
                        <div class="bloques">
                            <div class="bloque-uno-exito">
                                <img src="img/Imagen_Factura_Error.png" class="img-error-emision" />
                            </div>
                            <div class="bloque-dos-exito">
                                <p class="titulo-bloque-centro">ALGO SALIÓ MAL CON LA EMISIÓN DE TU FACTURA</p>
                                <p class="texto-bloque-error">
                                    Vuelve a intentarlo más tarde
                                </p>
                                <button class="volver-gris">VOLVER A INICIO</button>
                                <p class="texto-bloque-help">¿Necesitas ayuda?</p>
                            </div>
                        </div>
                    </div>
                </article>
                <section class="banner-promo">
                </section>
            </section>
        </>
    );
}