import React, { useRef, useState, useEffect } from "react";
import { useHistory ,useLocation } from "react-router-dom";
import { async, format } from "validate.js";
import func from "./FunctionsAdd"
import validate from "validate.js";
import Modal from "./Modals";
import ActionYoutubeSearchedFor from "material-ui/svg-icons/action/youtube-searched-for";

const schema = {
    RAZON_SOCIAL: {
        presence: { allowEmpty: false, message: "es requerida" },
        length: {
            maximum: 250,
            message: "maximo 250 caracteres",
        },
    },
    // CALLE: {
    //     presence: { allowEmpty: false, message: "es requerida" },
    //     length: {
    //         maximum: 70,
    //         message: "maximo 70 caracteres",
    //     },
    // },
    // COLONIA: {
    //     presence: { allowEmpty: false, message: "es requerida" },
    //     length: {
    //         maximum: 70,
    //         message: "maximo 70 caracteres",
    //     },
    // },
    // DELEGACION: {
    //     presence: { allowEmpty: false, message: "es requerida" },
    //     length: {
    //         maximum: 70,
    //         message: "maximo 70 caracteres",
    //     },
    // },
    // ESTADO: {
    //     presence: { allowEmpty: false, message: "es requerido" },
    //     length: {
    //         maximum: 70,
    //         message: "maximo 70 caracteres",
    //     },
    // },
    EMAIL: {
        presence: { allowEmpty: false, message: "es requerido" },
        email: {
            message: "no válido",
        },
        length: {
            maximum: 70,
            message: "maximo 70 caracteres",
        },
    },
    CODIGO_POSTAL: {
        presence: { allowEmpty: false, message: "es requerido" },
        length: {
            maximum: 5,
            message: "maximo 5 caracteres",
        },
        format: {
            // pattern: /\d{5}(-\d{4})?/,
            pattern: /^\d{4,5}/,
            message: "tiene un formato incorrecto",
        },
    },
    // cfdi:{
    //     presence: { allowEmpty: false, message: "es requerido" },
    //     length: {
    //         maximum: 100,

    //     },
    // }
};
const listRegimenFiscalJson = require('./listRegimenFiscal.json');
export default function DataClient(props) {
    const [onDisplay, setOnDisplay] = useState("none");
    const [onCurrenInfo, setOnCurrenInfo] = useState(undefined);
    const rfcRef = useRef(null);
    const mailRef = useRef();
    const edoRef = useRef();
    const cpRef = useRef();
    const delegacionRef = useRef();
    const razonSocialRef = useRef();
    const coloniaRef = useRef();
    const calleRef = useRef();
    const rfc2Ref = useRef();
    const regimenFisca = useRef();
    const history = useHistory();
    const [onModal, setOnModal] = useState(false);
    const [onTypeModal, setOnTypeModal] = useState('loader');
    const [listRegFis, setlistRegFis] = useState([]);
    const needHelp = () => { setOnTypeModal("helpContact"); setOnModal(true); };
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });
    const { state } = useLocation();
    useEffect(() => {
        if (rfcRef.current && state?.user?.rfcRegreso) {
          rfcRef.current.value = state.user.rfcRegreso;
          props.location.state.ticket =state.user.ticketRegreso

          findRFC();
        }
      }, [state]);
    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState((formState) => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);
    const hasError = (field) =>
        formState.touched[field] && formState.errors[field] ? true : false;

    if (props.location.state === undefined)
        history.push("/");
    //console.log(props);
    const goHome = () => { history.push("/"); };
    const goBack = () => {
        if (onDisplay == "")
            setOnDisplay("none");
        else
            history.goBack();
    };
    const goNext = async () => {
        debugger;
        let resjson;

        if (onCurrenInfo == undefined) {
            resjson = await insetData();
        } else {
            let doUpdate = false;
            for (let x in formState.values) {
                if(onCurrenInfo[x]== null && formState.values[x].toString().trim() != ''){
                    doUpdate = true;
                    break;
                }else if (formState.values[x].toString().trim() != onCurrenInfo[x].toString().trim()) {
                    doUpdate = true;
                    break;
                }
            }
            if (doUpdate) {
                resjson = await updateData();
            } else {
                resjson = { responseStatusCode: 1 };
            }
        }

        if (resjson.responseStatusCode == 1) {
            var path = {
                pathname: '/generaremision',
                state: {
                    articulos: props.location.state.tks,
                    ticket: props.location.state.ticket,
                    importe: props.location.state.importe,
                    rfc: rfcRef.current.value,
                    razonSocial: razonSocialRef.current.value,
                    calle: calleRef.current.value,
                    colonia: coloniaRef.current.value,
                    delegacion: delegacionRef.current.value,
                    estado: edoRef.current.value,
                    cp: cpRef.current.value,
                    email: mailRef.current.value,
                    fecha: func.FormatDatetoyyyyMMddHHmmss(new Date()),
                    regimenFiscaValue: regimenFisca.current.value,
                    fpago: props.location.state.fpago,
                    email: mailRef.current.value
                }
            }
            ////debugger
            history.push(path)

        } else {
            console.info("Modal del error por definir");
        }

    };
    const insetData = async () => {
        //debugger;
        let params = {
            rfc: rfcRef.current.value,
            razonSocial: razonSocialRef.current.value,
            calle: calleRef.current.value,
            colonia: coloniaRef.current.value,
            delegacion: delegacionRef.current.value,
            estado: edoRef.current.value,
            cp: cpRef.current.value,
            email: mailRef.current.value,
            fecha: func.FormatDatetoyyyyMMddHHmmss(new Date()),
            regimenFiscaValue: regimenFisca.current.value,
        }

        let resjson = await func.EjectFecth("insertaCliente", params);
        return resjson;
    }
    const updateData = async () => {
        let params = {
            rfc: rfcRef.current.value,
            razonSocial: razonSocialRef.current.value,
            calle: calleRef.current.value,
            colonia: coloniaRef.current.value,
            delegacion: delegacionRef.current.value,
            estado: edoRef.current.value,
            cp: cpRef.current.value,
            email: mailRef.current.value,
            fecha: func.FormatDatetoyyyyMMddHHmmss(new Date()),
            regimenFiscaValue: regimenFisca.current.value,
        }
        let resjson = await func.EjectFecth("updateCliente", params);
        return resjson;
    }
    const findRFC = async () => {
        if(rfcRef.current.value.trim() == ''){
            return
        }
        setOnModal(true);
        ////debugger
        let params = { rfc: rfcRef.current.value.toUpperCase() }
        let resjson = await func.EjectFecth("buscaRfc", params);
        // let resjson = { "responseStatusCode": 1, "cliente": [{ "RFC": "GUHI831129DM3", "RAZON_SOCIAL": "Ivan Gutierrez de Haro", "CALLE": "Venus 10", "COLONIA": "San Antonio Zomeyucan", "DELEGACION": "Naucalpan", "ESTADO": "Mexico", "CODIGO_POSTAL": 53750, "EMAIL": "29sagitario@gmail.com", "FECHA": "2013-10-01 17:07:35.000000", "ESTATUS": null, "USOCDFI": null }] };
        setOnDisplay("")

        let typeEntity = "";
        typeEntity = rfcRef.current.value.toString().length == 12 ? 0 : 1;
        ////debugger
        setlistRegFis(listRegimenFiscalJson.filter(
            x =>
                x.TypeEntity == typeEntity
        ))
        ////debugger
        document.getElementById('cfdidatos').value = '';
        if (resjson.responseStatusCode == 1) {
            setOnCurrenInfo(resjson.cliente[0])
            for (let x in resjson.cliente[0]) {
                if (resjson.cliente[0][x] != null) {
                    asignValue(x, resjson.cliente[0][x])

                } else if (x == 'REGIMENFISCAL') {
                    // document.getElementById('cfdidatos').value = '';
                }
            }
        } else {
            for (let x in formState.values) {
                asignValue(x, "")
            }
            asignValue("RFC", rfcRef.current.value)
        }






        setOnModal(false);
        setOnTypeModal('warningFact');
        setOnModal(true);
    };
    const asignValue = (nameVal, value) => {
       ////debugger
        formState.values[nameVal] = value;
        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [nameVal]: value.toString()
            },
            touched: {
                ...formState.touched,
                [nameVal]: true,
            },
        }));
        if(nameVal=='REGIMENFISCAL'){
            document.getElementById('cfdidatos').value = value;
        }
    }
    const handleChange = (event) => {
        ////debugger
        event.persist();
        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };
    // let typeEntity = "", listRegFis = [];
    // if (rfcRef.current != undefined) {
    //     typeEntity = rfcRef.current.value.toString().length == 12 ? 0 : 1;
    //     ////debugger
    //     listRegFis = listRegimenFiscalJson.filter(
    //         x =>
    //             x.TypeEntity == typeEntity
    //     )
    //     //document.getElementById('cfdidatos').value='';
    // }
    //let keycodeType = listRegFis.KeyCode
    //console.log(regimenFisca)
    const showHelp = () => { setOnTypeModal("razonSocilModal"); setOnModal(true); };
    const showCpmodal = () => { setOnTypeModal("modalCp"); setOnModal(true); };
    const showRegimenFis = () => { setOnTypeModal("modalRegimneFis"); setOnModal(true); };
    //console.log(keycodeType)
    //console.log(rfcRef.current.value);modalRegimneFis
    let modalMsg = onModal ? (<Modal setOnModal={setOnModal} type={onTypeModal} data={""} />) : ("");
    return (
        <>
            {modalMsg}
            <section className="contenido">
                <article id="blue-bloque">
                    <div className="banner-factura">
                        <div className="logo-blanco-bloque">
                            <img src="img/IMG_Logo_Blanco.png" className="logo-blanco" /></div>
                        <div className="titulo-ticket-bloque">
                            <p className="ticket-titulo" style={{ "lineHeight": "1.2em" }}>DATOS DEL CLIENTE</p>
                        </div>
                    </div>
                    <div className="info-ticket-cliente">
                        <div className="contenido-info-ticket-cliente">
                            <div className="rfc" style={{ display: onDisplay == "" ? "none" : "" }}>
                                <div className="title-rfc"><p className="texto-rfc">Ingresa tu RFC</p></div>
                                <div className="campo-rfc">
                                    <input id="rfc-space" maxLength="13" ref={rfcRef} />
                                </div>
                                <div className="search-rfc" onClick={findRFC}>Buscar</div>
                            </div>
                            <div className="registro-cliente" style={{ display: onDisplay }}>
                                <div className="two-column">
                                    <div className="column-del">
                                        <label htmlFor="rfc">RFC*</label><br />
                                        <input
                                            type="text"
                                            id="rfc"
                                            name="rfc"
                                            ref={rfc2Ref}
                                            value={formState.values.RFC || ""}
                                        />
                                    </div>
                                    <div className="column-estado">
                                        <label htmlFor="rfc">Regímen Fiscal</label><br />
                                        <div className="divCPModal">
                                        <select name="REGIMENFISCAL" id="cfdidatos" ref={regimenFisca} onChange={handleChange}>
                                            {/* <option value={''}></option> */}
                                            {listRegFis.map(elemento => (

                                                <option
                                                    key={elemento.KeyCode}
                                                    value={elemento.KeyCode}
                                                >{elemento.Descripcion}</option>
                                            )
                                            )}
                                        </select>
                                        <div className="question" onClick={showRegimenFis}>?</div>
                                        </div>
                                    </div>
                                </div>
                                <label htmlFor="name">Nombre o razón social*</label><br />
                                <div className="razon-Social-modal">
                                
                                <input
                                    maxlength="250"
                                    type="text"
                                    id="name"
                                    name="RAZON_SOCIAL"
                                    ref={razonSocialRef}
                                    value={formState.values.RAZON_SOCIAL || ""}
                                    onChange={handleChange}
                                />
                                <div className="question" onClick={showHelp}>?</div>
                                </div>
                                <>
                                    {hasError("RAZON_SOCIAL") ? (
                                        <span
                                            style={{
                                                color: "#dc0000",
                                                fontFamily: "circe, sans-serif",
                                                fontSize: "14px",
                                                display: "flex",
                                                // margin: "auto",
                                                // marginTop: "-11px"
                                            }}
                                        >
                                            {hasError("RAZON_SOCIAL") ? formState.errors.RAZON_SOCIAL[0] : null}
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                </>

                                {/* <br /> */}
                                <label htmlFor="calle">Calle y número</label><br />
                                <input
                                    maxlength="70"
                                    type="text"
                                    id="calle"
                                    name="CALLE"
                                    ref={calleRef}
                                    value={formState.values.CALLE || ""}
                                    onChange={handleChange}
                                />

                                <>
                                    {hasError("CALLE") ? (
                                        <span
                                            style={{
                                                color: "#dc0000",
                                                fontFamily: "circe, sans-serif",
                                                fontSize: "14px",
                                                display: "flex",
                                                // margin: "auto",
                                                // marginTop: "-11px"
                                            }}
                                        >
                                            {hasError("CALLE") ? formState.errors.CALLE[0] : null}
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                </>

                                {/* <br /> */}
                                <label htmlFor="colonia">Colonia</label><br />
                                <input
                                    maxlength="70"
                                    type="text"
                                    id="colonia"
                                    name="COLONIA"
                                    ref={coloniaRef}
                                    value={formState.values.COLONIA || ""}
                                    onChange={handleChange}
                                />

                                <>
                                    {hasError("COLONIA") ? (
                                        <span
                                            style={{
                                                color: "#dc0000",
                                                fontFamily: "circe, sans-serif",
                                                fontSize: "14px",
                                                display: "flex",
                                                // margin: "auto",
                                                // marginTop: "-11px"
                                            }}
                                        >
                                            {hasError("COLONIA") ? formState.errors.COLONIA[0] : null}
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                </>

                                {/* <br /> */}
                                <div className="two-column">
                                    <div className="column-del">
                                        <label htmlFor="delegacion">Delegación/Municipio</label><br />
                                        <input
                                            maxlength="70"
                                            type="text"
                                            id="delegacion"
                                            name="DELEGACION"
                                            ref={delegacionRef}
                                            value={formState.values.DELEGACION || ""}
                                            onChange={handleChange}
                                        />

                                        <>
                                            {hasError("DELEGACION") ? (
                                                <span
                                                    style={{
                                                        color: "#dc0000",
                                                        fontFamily: "circe, sans-serif",
                                                        fontSize: "14px",
                                                        display: "flex",
                                                        // margin: "auto",
                                                        // marginTop: "-11px"
                                                    }}
                                                >
                                                    {hasError("DELEGACION") ? formState.errors.DELEGACION[0] : null}
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                        {/* kc */}
                                    </div>
                                    <div className="column-estado">
                                        <label htmlFor="estado">Estado</label><br />
                                        <input
                                            maxlength="70"
                                            type="text"
                                            id="estado"
                                            name="ESTADO"
                                            ref={edoRef}
                                            value={formState.values.ESTADO || ""}
                                            onChange={handleChange}
                                        />

                                        <>
                                            {hasError("ESTADO") ? (
                                                <span
                                                    style={{
                                                        color: "#dc0000",
                                                        fontFamily: "circe, sans-serif",
                                                        fontSize: "14px",
                                                        display: "flex",
                                                        // margin: "auto",
                                                        // marginTop: "-11px"
                                                    }}
                                                >
                                                    {hasError("ESTADO") ? formState.errors.ESTADO[0] : null}
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                        </>

                                    </div>
                                </div>
                                <div className="two-column">
                                    <div className="column-cp">
                                    <label htmlFor="cp">Código Postal*</label><br />
                                            <div className="divCPModal">
                                        <input
                                            type="text"
                                            id="cp"
                                            name="CODIGO_POSTAL"
                                            ref={cpRef}
                                            value={formState.values.CODIGO_POSTAL || ""}
                                            onChange={handleChange}
                                        />
                                        <>
                                            {hasError("CODIGO_POSTAL") ? (
                                                <span
                                                    style={{
                                                        color: "#dc0000",
                                                        fontFamily: "circe, sans-serif",
                                                        fontSize: "14px",
                                                        display: "flex",
                                                        // margin: "auto",
                                                        // marginTop: "-11px"
                                                    }}
                                                >
                                                    {hasError("CODIGO_POSTAL") ? formState.errors.CODIGO_POSTAL[0] : null}
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                        <div className="question" onClick={showCpmodal}>?</div>
                                        </div>
                                    </div>
                                    
                                    <div className="column-correo">
                                        <label htmlFor="correo">Correo electrónico*</label><br />
                                        <input
                                            maxlength="70"
                                            type="email"
                                            id="correo"
                                            name="EMAIL"
                                            ref={mailRef}
                                            value={formState.values.EMAIL || ""}
                                            onChange={handleChange}
                                        />

                                        <>
                                            {hasError("EMAIL") ? (
                                                <span
                                                    style={{
                                                        color: "#dc0000",
                                                        fontFamily: "circe, sans-serif",
                                                        fontSize: "14px",
                                                        display: "flex",
                                                        // margin: "auto",
                                                        // marginTop: "-11px"
                                                    }}
                                                >
                                                    {hasError("EMAIL") ? formState.errors.EMAIL[0] : null}
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                        </>

                                    </div>
                                </div>
                                <p><label htmlFor="correo">*Campo obligatorios</label></p>
                            </div>
                            <div className="botones-genera-ticket">
                                <button className="btn-atras-ticket" onClick={goBack}>ATRÁS</button>
                                <button className="btn-inicio-ticket" onClick={goHome}>INICIO</button>
                                <button className="btn-next-ticket" onClick={goNext} disabled={!formState.isValid}>SIGUIENTE</button>
                            </div>
                        </div>
                    </div>
                </article>
                <section className="banner-gris-clientef">
                    <div className="bloque-gris-help">
                        <div className="gris-contenido-helpf">
                            <p className="gris-texto-help" onClick={needHelp}>¿Necesitas ayuda?</p>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}