import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { async, format } from "validate.js";
import func from "./FunctionsAdd"
import validate from "validate.js";
import exportFromJSON from 'export-from-json'
import Modal from "./Modals";
const usoCFDIJson = require('./usoCFDI.json');
const listRegimenFiscalJson = require('./listRegimenFiscal.json');
export default function GenerateEmission(props) {
    const [onModal, setOnModal] = useState(false);
    const [onModalError, setOnModalError] = useState(false);
    const [onModalErrorMSG, setOnModalErrorMSG] = useState('');
    const [onTypeModal, setOnTypeModal] = useState('');
    const [onDisplay, setOnDisplay] = useState("none");
    const [botonActivo, setBotonActivo] = useState(false)
    const needHelp = () => { setOnTypeModal("helpContact"); setOnModal(true); }
    const usoCFDIref = useRef();
    const typeCFDIref = useRef();
    const [typeFac, setTypeFac] = useState('pdf');
    const ShowLoader = () => { setOnTypeModal("loader"); setOnModal(true); };
    const goHome = () => { history.push("/"); };
    const goPolicys = () => { history.push("/privacy"); };
    if (props.location.state === undefined)
        history.push("/");

    const rfcRef = useRef();
    let modalError = '';
    const [isChecked, setIsChecked] = useState(false)
    let ticket = props.location.state.ticket;
    let fecha = ticket.substring(0, 6);
    let anio = "20" + fecha.substring(0, 2);
    let mes = fecha.substring(2, 4);
    let dia = fecha.substring(4, 6);
    let tienda = ticket.substring(6, 10);
    let terminal = ticket.substring(10, 13);
    let transaccion = ticket.substring(13, 17)
    let typeEntity = parseInt(props.location.state.regimenFiscaValue);
    //console.log(props.location.state)
    const history = useHistory();
    let modalMsg = onModal ? (<Modal setOnModal={setOnModal} type={onTypeModal} data={""} />) : ("");
    //let modalMsg = onModal ? (<Modal setOnModal={setOnModal} type={onTypeModal} data={""} />) : ("");
    let usoCFDI = usoCFDIJson.filter(
        x =>
            x.ClavesDelRegimen.includes(typeEntity)
        //&& x.TypeEntity < typeEntity

    )
    let regimenDesc = [];
    regimenDesc = listRegimenFiscalJson.find(
        element =>
            element.KeyCode == props.location.state.regimenFiscaValue
    );
    //console.log(regimenDesc.Descripcion)
    //console.log(usoCFDI)
    // let usoCFDI = usoCFDIJson.filter(
    //     x => x.ClavesDelRegimen.includes(typeEntity)

    // )
    // //debugger;
    const goBack = () => {
        if (onDisplay == "")
            setOnDisplay("none");
        else
            history.goBack();
    };
    const closeModalError = () => {
        setOnModalError(false)
        setOnModal(false);
        const user = { rfcRegreso: props.location.state.rfc, ticketRegreso: props.location.state.ticket };
        history.push({
            pathname: '/generardatos',
            state: { user }
        });
    }
    if (onModalError) {
        debugger
        let msgCP = onModalErrorMSG.split(":")
        console.log(msgCP[0])
        modalError = (
            <section class="contenido-modal-column">
                <div class="modal-column">
                    <div class="contenido-bloques">
                        <div class="bloques">
                            <div class="bloque-uno-error">
                                <img src="img/IMG_CANCELAR_ERROR.png" class="img-bloque-error" style={{ "cursor": "pointer" }} />
                            </div>
                            <div class="bloque-dos-error">
                                <p class="titulo-bloque-centro">¡ALGO SALIÓ MAL!</p>
                                <p class="texto-bloque-error">
                                    {msgCP[0] == "CFDI40147" ? 'El Código Postal no coincide con el que aparece en su Constancia de Situación Fiscal del Receptor' : onModalErrorMSG}
                                </p>
                                <button class="back" onClick={closeModalError}>REGRESAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
    const generateEmision = async () => {

        if (isChecked) {
            ShowLoader();
            ////debugger
            let params = {
                ticket: props.location.state.ticket,
                usoCFDI: usoCFDIref.current.value,
                rfc: props.location.state.rfc,
                regimenFiscal: typeEntity,
                email: props.location.state.email,
                typeEmail: typeFac
            }
            //console.log(params)
            let resjson = await func.EjectFecth("generafactura", params);
            if (resjson.respuestaFacura.genFact === false) {
                setOnModalError(true)

                let newRespuesta = resjson.respuestaFacura.respuesta.split('|')
                //console.log(newRespuesta[5])
                setOnModalErrorMSG(newRespuesta[4])
                //setOnTypeModal("printError");
                //respuestaFacura.genFact
            } else {
                let path = {
                    pathname: '/facturaemitida'
                };
                switch (typeFac) {
                    case 'pdf':
                        generaPdfyXml(resjson);

                        history.push(path)
                        break;
                    case 'email':

                        //console.log('se fue por mail')
                        history.push(path)

                        break;
                    case 'ambos':
                        generaPdfyXml(resjson);
                        history.push(path)
                        break;


                }

            }
        }
        setOnTypeModal("avisoDePoliticas");
        setOnModal(true);
        // setOnModalErrorMSG('Favor de aceptar terminos y condiciones')
        // setOnModalError(true)

    }
    const generaPdfyXml = (resjson) => {
        const linkSource = `data:application/pdf;base64,${resjson.respuestaFacura.pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = `${resjson.respuestaFacura.folio}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        const linkSourcexml = `data:application/pdf;base64,${resjson.respuestaFacura.xml}`;
        const downloadLinkxml = document.createElement("a");
        const fileNamexml = `${resjson.respuestaFacura.folio}.xml`;
        downloadLinkxml.href = linkSourcexml;
        downloadLinkxml.download = fileNamexml;
        downloadLinkxml.click();
    }
    const handleChangeGender = (event) => {
        setTypeFac(event.target.id);
        //console.log(event.target.id);
    };
    const handleChangeBoton = (e) => {
        //debugger
        if (e.target.value != '') {
            setBotonActivo(true)
        } else {
            setBotonActivo(false)
        }
        //console.log(e.target.value)
    }
    useEffect(() => {
        document.getElementById('pdf').checked = true;
    }, [])
    const handleOnChange = () => {
        //console.log(isChecked)
        setIsChecked(!isChecked);
    };

    return (
        <>
            {modalMsg}
            {modalError}
            <section class="contenido">
                <article id="blue-bloque">
                    <div class="banner-factura">
                        <div class="logo-blanco-bloque">
                            <img src="img/IMG_Logo_Blanco.png" class="logo-blanco" /></div>
                        <div class="titulo-ticket-bloque">
                            <p class="ticket-titulo">EMISIÓN</p>
                        </div>
                    </div>
                    <div class="info-ticket-cliente" >
                        <div class="contenido-info-ticket-cliente">
                            <p class="texto-emision"><b>Se emitirá la factura con los siguientes datos:</b></p>
                            <p class="texto-emision-fecha"><b>Fecha de ticket:</b> {dia}-{mes}-{anio}</p>
                            <div class="dos-column">
                                <div class="column-izq">
                                    <p class="texto-emision-tienda"><b>Ticket:</b> {props.location.state.ticket || ""}</p>
                                    <div class="tres-column">
                                        <div class="emision-rfc">
                                            <p class="texto-emosion-rfc"><b>RFC:</b> {props.location.state.rfc || ""}

                                            </p>
                                        </div>
                                        <div class="transaccion-emision">
                                            <p class="texto-emosion-transaccion"><b>Regimen fiscal:</b> {regimenDesc.Descripcion || ""}</p>
                                        </div>
                                        <div class="caja-emision">
                                            <p class="texto-emosion-caja"><b>Razón social:</b> {props.location.state.razonSocial || ""}</p>
                                        </div>

                                    </div>
                                    <p class="texto-emision-razon"><b>CP:</b>  {props.location.state.cp || ""}</p>
                                    <p class="texto-emision-email"><b>E-mail:</b> {props.location.state.email || ""}</p>
                                    <p class="texto-emision-email"><b>Forma de pago:</b> {props.location.state.fpago || ""}</p>
                                    <p class="texto-emision-cfdi"><b>Uso del CFDI:</b></p>
                                    <select name="cfdi" id="cfdi" ref={usoCFDIref} onChange={handleChangeBoton}>
                                        <option value={''}></option>
                                        {usoCFDI.map(elemento => (
                                            <option key={elemento.KeyCode} value={elemento.KeyCode}>{elemento.Description}</option>
                                        )
                                        )}
                                    </select>
                                    <p class="texto-emision-factura">Su factura se puede recibir de las siguientes formas:</p>
                                    <input type="radio" onClick={handleChangeGender} id="pdf" name="formato" value="PDF" style={{ width: "none" }} />
                                    <label for="pdf" class="formato">PDF</label><br />
                                    <input type="radio" onClick={handleChangeGender} id="email" name="formato" value="EMAIL" />
                                    <label for="email" class="formato"> E-mail</label><br />
                                    <input type="radio" onClick={handleChangeGender} id="ambos" name="formato" value="Ambos" />
                                    <label for="ambos" class="formato">Ambos</label>
                                </div>
                                <div class="column-der">
                                    <div class="aviso-emision">
                                        <p class="texto-emision-aviso">AVISO</p>
                                    </div>
                                    <div class="contenido-aviso">
                                        <p class="texto-contenido-aviso">Para obtener un factura se requiere lo siguiente:</p>
                                        <p class="texto-contenido-aviso">• Adobe Reader</p>
                                        <p class="texto-contenido-aviso">• Activar las ventanas emergentes</p>
                                    </div>
                                </div>
                            </div>

                            <p class="aviso-priv" >
                                <input
                                    type="checkbox"
                                    id="avisoDePri"
                                    name="aviso"
                                    value="avisoPriv"
                                    checked={isChecked}
                                    onChange={handleOnChange}
                                /> Acepto que he leído y acepto la <span class="azul-bold" style={{ cursor: "pointer" }} onClick={goPolicys}>politica de privacidad</span></p>
                            <div class="botones-genera-ticket">
                                <button class="btn-atras-ticket" onClick={goBack} >ATRÁS</button>
                                <button class="btn-inicio-ticket" onClick={goHome} >INICIO</button>
                                <button class="btn-next-ticket " disabled={!botonActivo} onClick={generateEmision}>SIGUIENTE</button>
                                {/* btn-next-ticket */}
                            </div>
                        </div>
                    </div>
                </article>
                <section class="banner-gris-cliente">

                    <div class="bloque-gris-help">
                        <div class="gris-contenido-help-generar">
                            <p class="gris-texto-help" onClick={needHelp}>¿Necesitas ayuda?</p>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}