import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import func from "./FunctionsAdd"
import validate from "validate.js";
import Modal from "./Modals";
const cancelacionCFDI = require('./cancelacionFCDI.json');

const schema = {
    Ticket: {
        presence: { allowEmpty: false, message: "es requerido" },
        length: {
            maximum: 19,
            message: "maximo 19 caracteres",
        },
    },
    Uuid: {
        presence: { allowEmpty: false, message: "es requerida" },
        length: {
            maximum: 8,
            message: "maximo 8 caracteres",
        },
    },
    Uuid2: {
        presence: { allowEmpty: false, message: "es requerida" },
        length: {
            maximum: 4,
            message: "maximo 8 caracteres",
        },
    },
    Uuid3: {
        presence: { allowEmpty: false, message: "es requerida" },
        length: {
            maximum: 4,
            message: "maximo 8 caracteres",
        },
    },
    Uuid4: {
        presence: { allowEmpty: false, message: "es requerida" },
        length: {
            maximum: 4,
            message: "maximo 8 caracteres",
        },
    },
    Uuid5: {
        presence: { allowEmpty: false, message: "es requerida" },
        length: {
            maximum: 12,
            message: "maximo 8 caracteres",
        },
    }
};

export default function Cancel() {
    const [onModal, setOnModal] = useState(false);
    const [onTypeModal, setOnTypeModal] = useState('ticketInvoiced');
    const cancelaCFDIref = useRef();
    const ticketRef = useRef();
    const UIIDRef = useRef();
    const UIIDRef2 = useRef();
    const UIIDRef3 = useRef();
    const UIIDRef4 = useRef();
    const UIIDRef5 = useRef();
    const history = useHistory();
    const goHome = () => { history.goBack() };
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });
    let cancelaDFCI = cancelacionCFDI.filter(
        x =>
            x.Status ==1
    )
    let modalMsg = onModal ? (<Modal setOnModal={setOnModal} type={onTypeModal} data={""} />) : ("");
    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState((formState) => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);
    const handleChange = (event) => {
        event.persist();
        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };
    const needHelp = () => { setOnTypeModal("helpContact"); setOnModal(true); };

    const cancelHelp = () => { setOnTypeModal("helpCancel"); setOnModal(true); };
    const cancelaTicket = async () => {
        ////debugger
        let params = { ticket: ticketRef.current.value, Uiid: `${UIIDRef.current.value}-${UIIDRef2.current.value}-${UIIDRef3.current.value}-${UIIDRef4.current.value}-${UIIDRef5.current.value}` }
        let resjson = await func.EjectFecth("cancelaFactura", params);
        //console.log(params)
    }

    return (
        <>
            {modalMsg}
            <section class="contenido">
                <article id="left-info">
                    <div class="logo-azul">
                        <img src="img/IMG_LOGO_AZUL.png" class="logo-blue" />
                    </div>
                    <div class="contenido-accion">
                        <p class="titulo-accion">Por favor escriba el número de ticket</p>
                        <div class="contenido-column">
                            <div class="numero-ticket">
                                <div class="icon-num">
                                    <img src="img/icon-ticket.png" class="icon-archive" />
                                </div>
                                <div class="texto-num">
                                    <input placeholder="Número de ticket o ID facturación"
                                        class="num-ticket"
                                        ref={ticketRef}
                                        value={formState.values.Ticket || ""}
                                        onChange={handleChange}
                                        type="text"
                                        name="Ticket"
                                    />
                                </div>
                                <div class="question" onClick={cancelHelp}>?</div>
                            </div>
                            <p class="titulo-accion">Escriba el UUID</p>
                            <div class="codigo-uuid">
                                <div class="four">
                                    <input class="code"
                                        ref={UIIDRef}
                                        value={formState.values.Uuid || ""}
                                        onChange={handleChange}
                                        type="text"
                                        name="Uuid"
                                    />
                                </div>
                                <p class="guion">-</p>
                                <div class="two">
                                    <input class="code-two"
                                        ref={UIIDRef2}
                                        value={formState.values.Uuid2 || ""}
                                        onChange={handleChange}
                                        type="text"
                                        name="Uuid2"
                                    />
                                </div>
                                <p class="guion">-</p>
                                <div class="two">
                                    <input class="code-two"
                                        ref={UIIDRef3}
                                        value={formState.values.Uuid3 || ""}
                                        onChange={handleChange}
                                        type="text"
                                        name="Uuid3"
                                    />
                                </div>
                                <p class="guion">-</p>
                                <div class="two">
                                    <input class="code-two"
                                        ref={UIIDRef4}
                                        value={formState.values.Uuid4 || ""}
                                        onChange={handleChange}
                                        type="text"
                                        name="Uuid4"
                                    />
                                </div>
                                <p class="guion">-</p>
                                <div class="four">
                                    <input class="code"
                                        ref={UIIDRef5}
                                        value={formState.values.Uuid5 || ""}
                                        onChange={handleChange}
                                        type="text"
                                        name="Uuid5"
                                    />
                                </div>
                            </div>

                            {/* <p class="titulo-accion">Motivo de cancelacion de CFDI:</p>
                            <select class="motivoCan" ref={cancelaCFDIref}>
                                {cancelaDFCI.map(elemento => (
                                    <option key={elemento.KeyCode} value={elemento.KeyCode}>{elemento.Description}</option>
                                )
                                )}
                            </select> */}

                            <div class="btn-contenido">
                                <button class="regresar" onClick={goHome}>REGRESAR</button>
                                <button class="enviar" onClick={cancelaTicket} disabled={!formState.isValid}>ENVIAR</button>
                            </div>
                        </div>
                    </div>
                    <div class="help">
                        <div class="question3" onClick={cancelHelp}>?</div>
                        <p class="texto-help" onClick={needHelp}>¿Necesitas ayuda?</p>
                    </div>
                </article>
                <article id="right-img">
                    <div class="img-info-cancelar"></div>
                </article>
            </section>
        </>
    );
}