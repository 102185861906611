import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import func from "./FunctionsAdd"
import Modal from "./Modals";
import exportFromJSON from 'export-from-json'


export default function Reprint() {

    const history = useHistory();
    const goHome = () => { history.goBack(); };
    const [onModal, setOnModal] = useState(false);
    const [onTypeModal, setOnTypeModal] = useState('loader');

    let modalMsg = onModal ? (<Modal setOnModal={setOnModal} type={onTypeModal} data={""} />) : ("");
    const cancelHelp = () => { setOnTypeModal("helpCancel"); setOnModal(true); };
    const needHelp = () => { setOnTypeModal("helpContact"); setOnModal(true); };
    const ShowLoader = () => { setOnTypeModal("loader"); setOnModal(true); };
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });

    const Ticket = useRef();
    const handleChange = (event) => {
        event.persist();
        setFormState((formState) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };

    const rePrint = async () => {
        
        let params = { ticketPdf: Ticket.current.value, ticketXml: Ticket.current.value };
        if(params.ticketPdf != '' && params.ticketXml != ''){
            setOnModal(true);
            //console.log(params);
            ////debugger;
            let resjson = await func.EjectFecth("reimprimeFactura",params);
            ////debugger;
            if(resjson.pdfFile && resjson.xmlFile){
                const linkSource = `data:application/pdf;base64,${resjson.pdfFile}`;
                const downloadLink = document.createElement("a");
                const fileName = `${resjson.folio}`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
    
                const linkSourceXML = `data:application/pdf;base64,${resjson.xmlFile}`;
                const downloadLinkXML = document.createElement("a");
                const fileNameXML = `${resjson.folio}.xml`;
                downloadLinkXML.href = linkSourceXML;
                downloadLinkXML.download = fileNameXML;
                downloadLinkXML.click();
                setOnModal(false);
            }
            else{
                setOnModal(false);
            }
        }else{
            //console.log("campos requeridos");
        }
        
        
        
            
    }

    return (
        <>
        {modalMsg}
            <section class="contenido">
                <article id="left-info">
                    <div class="logo-azul">
                        <img src="img/IMG_LOGO_AZUL.png" class="logo-blue" />
                    </div>
                    <div class="contenido-accion">
                        <p class="titulo-accion">Por favor escriba el número de ticket</p>
                        <div class="contenido-column">
                            <div class="numero-ticket">
                                <div class="icon-num">
                                    <img src="img/icon-archive.png" class="icon-archive" />
                                </div>
                                <div class="texto-num">
                                    <input 
                                        placeholder="Número de ticket o ID facturación" 
                                        class="num-ticket"
                                        ref={Ticket}
                                        onChange={handleChange}
                                        />
                                </div>
                                <div class="question" onClick={cancelHelp}>?</div>
                            </div>
                            <div class="btn-contenido-reimprimir">
                                <button class="enviar" onClick={rePrint}>ENVIAR</button>
                            </div>
                            <div class="requerimientos">
                                <div class="subtitle-reque">
                                    <p class="texto-reque">Para imprimir su factura se requiere:</p>
                                </div>
                                <div class="info-reque">
                                    <ul class="texto-info-reque">
                                        <li>Adobe Reader</li>
                                        <li>Desbloquear las ventanas emergente</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="btn-contenido-reimprimir">
                                <button class="regresar" onClick={goHome}>REGRESAR</button>
                            </div>
                        </div>
                    </div>
                    <div class="help">
                        <div class="question3" onClick={cancelHelp}>?</div>
                        <p class="texto-help" onClick={needHelp}>¿Necesitas ayuda?</p>
                    </div>
                </article>
                <article id="right-img">
                    <div class="img-info-reimprimir"></div>
                </article>
            </section>
        </>
    );
}